import React, { useState, useEffect } from 'react';
import { Droplet, RefreshCw, Activity } from 'lucide-react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
const WaterLevelDashboard = () => {
    const [waterLevel, setWaterLevel] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [rawData, setRawData] = useState(null);
    const [waterData, setWaterData] = useState([]);

    // ThingSpeak 設置
    const channelId = 2598461;
    const apiKey = "BEC7TUKU3F9CRYT0";
    const fieldNumber = 1;

    const fetchData = async () => {
        setLoading(true);
        const endDate = new Date();
        const startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000); // 24小時之前

        try {
            const response = await fetch(`https://api.thingspeak.com/channels/${channelId}/fields/${fieldNumber}.json?api_key=${apiKey}&start=${startDate.toISOString()}&end=${endDate.toISOString()}`);
            if (!response.ok) throw new Error('API呼叫失敗，狀態碼：' + response.status);
            const data = await response.json();

            const levels = data.feeds.map(feed => ({
                time: feed.created_at,
                level: Math.max(0, Math.min(100, ((300 - parseFloat(feed.field1)) / 300) * 100))
            }));

            setWaterData(levels);
            setWaterLevel(levels.length > 0 ? levels[levels.length - 1].level : 0);
            setLastUpdated(new Date(data.feeds[data.feeds.length - 1].created_at).toLocaleString());
            setRawData(data.feeds[data.feeds.length - 1]);
            setLoading(false);
            setError(null);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 15000);
        return () => clearInterval(interval);
    }, []);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#FFFFFF', // 白色字體
                    font: {
                        size: 18, // 增加到更大的字體大小
                        family: 'Arial, sans-serif',
                        weight: 'bold',
                        color: '#000000' // 改為黑色以增加對比
                    }
                }
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                bodyFont: {
                    size: 18, // 同樣增加提示工具的字體大小
                    family: 'Arial, sans-serif'
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    displayFormats: {
                        minute: 'HH:mm' // 調整為更合適的時間格式
                    }
                },
                grid: {
                    color: '#cccccc' // 更淺的格線顏色
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 24,
                    maxRotation: 0,
                    minRotation: 0,
                    color: '#FFFFFF', // 白色字體
                    font: {
                        size: 16,
                        color: '#FFFFFF', // 白色字體
                    }
                }
            },
            y: {
                beginAtZero: true,
                suggestedMax: 100,
                color: '#FFFFFF', // 白色字體
                grid: {
                    color: '#cccccc'
                },
                ticks: {
                    color: '#FFFFFF', // 白色字體
                    font: {
                        size: 16,
                        color: '#000000'
                    }
                }
            }
        },
        elements: {
            point: {
                radius: 3,
                hoverRadius: 8
            },
            line: {
                borderWidth: 4
            }
        }
    };
    

    const chartData = {
        labels: waterData.map(d => new Date(d.time)),
        datasets: [
            {
                label: '水位 (%)',
                data: waterData.map(d => d.level),
                fill: true,
                borderColor: 'rgb(75, 192, 192)', // 線條顏色
                backgroundColor: 'rgba(75, 192, 192, 0.2)', // 填充顏色
                pointBackgroundColor: 'rgb(75, 192, 192)' // 數據點顏色
            }
        ]
    };

    return (
        <div className="flex flex-col items-center p-8 bg-gradient-to-br from-blue-500 to-purple-600 min-h-screen text-white">
            <h2 className="text-4xl font-bold mb-8 animate-pulse">水位監測</h2>

            <div className="bg-white bg-opacity-20 rounded-xl p-8 backdrop-blur-md shadow-lg w-full max-w-md">
                <div className="relative h-64 mb-6">
                    <div className="absolute inset-0 bg-blue-200 rounded-lg overflow-hidden">
                        <div
                            className="absolute bottom-0 left-0 right-0 bg-blue-500 transition-all duration-1000 ease-in-out"
                            style={{ height: `${waterLevel}%`, background: 'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)' }}
                        >
                            <div
                                className="absolute top-0 left-0 w-full h-full bg-blue-400 opacity-50 animate-wave animation-delay-500"
                                style={{ animation: 'flow 3s linear infinite, wave 5s linear infinite' }}
                            ></div>
                            <div
                                className="absolute top-0 left-0 w-full h-full bg-blue-300 opacity-30 animate-wave animation-delay-200"
                                style={{ animation: 'flow 3s linear infinite, wave 5s linear infinite, pulse 3s ease-in-out infinite' }}
                            ></div>
                        </div>
                    </div>
                    <Droplet className="absolute top-4 left-1/2 transform -translate-x-1/2 text-blue-600 animate-bounce" size={48} />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <span className="text-5xl font-bold text-white drop-shadow-lg">
                            {waterLevel.toFixed(1)}%
                        </span>
                    </div>
                </div>


                {loading ? (
                    <p className="text-xl text-center">載入中<span className="animate-pulse">...</span></p>
                ) : error ? (
                    <p className="text-xl text-red-300 text-center">{error}</p>
                ) : (
                    <>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-lg">最後更新</span>
                            <span className="text-lg font-semibold">{lastUpdated}</span>
                        </div>
                        {rawData && (
                            <div className="bg-white bg-opacity-20 rounded-lg p-4 mb-4">
                                <h3 className="text-lg font-semibold mb-2 flex items-center">
                                    <Activity className="mr-2" size={20} />
                                    原始數據
                                </h3>
                                <p>距離: {rawData.field1} cm</p>
                                <p>ID: {rawData.entry_id}</p>
                            </div>
                        )}
                    </>
                )}

                <button
                    onClick={fetchData}
                    className="w-full mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-full transition-colors flex items-center justify-center"
                    disabled={loading}
                >
                    <RefreshCw className={`mr-2 ${loading ? 'animate-spin' : ''}`} size={20} />
                    刷新數據
                </button>
            </div>
            <p>　</p>
            <div className="w-full md:max-w-4xl lg:max-w-6xl xl:max-w-8xl" style={{ height: '500px' }}>
    <Line options={options} data={chartData} />
</div>

        </div>
    );
};

export default WaterLevelDashboard;
