import React from 'react';
import WaterTower from './WaterTower';

function App() {
  return (
    <div className="App">
      <WaterTower />
    </div>
  );
}

export default App;